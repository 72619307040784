import React, {useEffect, useState} from 'react';
import {Button, Card, Form, InputGroup} from "react-bootstrap";
import styles from "../Create/Create.module.css";
import "../Create/Create.module.css";
import saveAs from 'file-saver'


export default function Create() {

    useEffect(() => {
        document.body.style.backgroundColor = "#e3d5ca"
    })
    const [quiz, setQuiz] = useState({
        id: Math.floor(100000 + Math.random() * 900000),
        name: '',
        author: '',
        created: new Date().toUTCString(),
        questions: [
            {
                question: '',
                answers: [
                    {value: '', isCorrect: false},
                    {value: '', isCorrect: false},
                    {value: '', isCorrect: false},
                    {value: '', isCorrect: false},
                ],
            },
        ],
    });

    const handleChange = (event, slideIndex, answerIndex) => {
        const {name, value, type, checked} = event.target;

        if (type === 'checkbox') {
            // Handle checkbox input for isCorrect
            const updatedQuestions = [...quiz.questions];
            updatedQuestions[slideIndex].answers[answerIndex].isCorrect = checked;
            setQuiz({...quiz, questions: updatedQuestions});
        } else if (name.startsWith('answer')) {
            // Handle text input changes for answers
            const updatedQuestions = [...quiz.questions];
            updatedQuestions[slideIndex].answers[answerIndex].value = value;
            setQuiz({...quiz, questions: updatedQuestions});
        }
    };

    const handleNameChange = (event) => {
        const {name, value} = event.target;
        setQuiz({...quiz, [name]: value});
    };

    const handleSlideChange = (event, slideIndex) => {
        const {name, value} = event.target;
        const updatedQuestions = [...quiz.questions];
        updatedQuestions[slideIndex][name] = value;
        setQuiz({...quiz, questions: updatedQuestions});
    };

    const handleAddSlide = () => {
        const newSlide = {
            question: '',
            answers: [
                {value: '', isCorrect: false},
                {value: '', isCorrect: false},
                {value: '', isCorrect: false},
                {value: '', isCorrect: false},
            ],
        };

        setQuiz({...quiz, questions: [...quiz.questions, newSlide]});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission, e.g., send data to server
        const quizName = quiz.id + "-123456-quiz.json";
        const file = new Blob([JSON.stringify(quiz)], {type: 'application/json;charset=utf-8'});
        saveAs(file, quizName);
    };

    return (
        <div className={styles.Create}>
            <Card className="col-lg-6 shadow-lg bg-body-tertiary rounded">
                <h1 className="mx-auto pt-3">Create a Quiz!</h1>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <div className="row mb-4">
                            <Form.Group className="col-lg-6">
                                <Form.Label className={styles.form}>Quiz Name:</Form.Label>
                                <Form.Control className="" type="text" id="name" name="name" value={quiz.name}
                                              onChange={handleNameChange} autoFocus={true}/>
                                <small className="text-muted ms-2">id: {quiz.id.toLocaleString("no-NB")}</small>
                            </Form.Group>
                            <Form.Group className="col-lg-6">
                                <Form.Label className={styles.form}>Author:</Form.Label>
                                <Form.Control type="text" id="author" name="author" value={quiz.author}
                                              onChange={handleNameChange}/>
                            </Form.Group>
                        </div>
                        {quiz.questions.map((slide, slideIndex) => (
                            <div key={slideIndex}>
                                <hr/>
                                <div className="row mb-4">
                                    <Form.Group className="col-lg-10">
                                        <Form.Label htmlFor={`question-${slideIndex}`}
                                                    className={styles.form}>Question {slideIndex + 1}:</Form.Label>
                                        <Form.Control type="text" id={`question-${slideIndex}`} name="question"
                                                      value={slide.question}
                                                      onChange={(e) => handleSlideChange(e, slideIndex)}/>
                                    </Form.Group>
                                    <div className="col-lg-2 text-center my-auto">
                                        <Button type="submit" className="btn-danger"
                                                disabled={true}>Remove</Button>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    {slide.answers.map((answer, answerIndex) => (
                                        <div key={answerIndex} className="col-lg-6 my-2">
                                            <Form.Label htmlFor={`name-answer-${slideIndex}-${answerIndex}`}
                                                        className={styles.form}>Answer {answerIndex + 1}:</Form.Label>
                                            <InputGroup name={`name-answer-${slideIndex}-${answerIndex}`}>
                                                <Form.Control type="text" id={`answer-${slideIndex}-${answerIndex}`}
                                                              name={`answer-${slideIndex}-${answerIndex}`}
                                                              value={answer.value}
                                                              className={"answer" + (answerIndex + 1)}
                                                              onChange={(e) => handleChange(e, slideIndex, answerIndex)}/>

                                                <InputGroup.Checkbox
                                                    checked={answer.isCorrect}
                                                    onChange={(e) => handleChange(e, slideIndex, answerIndex)}/>
                                            </InputGroup>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <Button type="button" onClick={handleAddSlide} className="btn-success mt-0 col-lg-2">
                            Add Question
                        </Button>
                        <Button type="submit" className="col-lg-12 mx-auto mt-5 btn-md">Download quiz</Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}
