import React, {useEffect, useState} from 'react';
import Error from "../Error/Error";
import styles from './QuestionSlide.module.css'
import quiz from '../Quiz/123456-quiz.json'
import {Link} from "react-router-dom";
import {Button, Card, Form} from "react-bootstrap";
import FortuneWheel from "../FortuneWheel/FortuneWheel";
import ReactPlayer from "react-player";
import sound from "../audio.mp3"

// Source: https://codesandbox.io/s/spinning-prize-wheel-react-fyu57?file=/src/styles.css:0-865

export default function QuestionSlide() {
    const [questionId, setQuestionId] = useState(0);
    const [isShowAnswer, setIsShowAnswer] = useState(false);
    const audio = new Audio(sound);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        if (queryParameters.has("id")) {
            const id = queryParameters.get("id")
            if (!queryParameters.has("id") || isNaN(id)) {
                return (
                    <Error httpCode={400} message="Not a valid questionId"/>
                )
            }
            updateQuestionId(Number(id))
        }
    }, []);

    function getNextQuestionId() {
        if ((questionId + 1) < quiz.questions.length) {
            return questionId + 1
        } else {
            return 1337
        }
    }

    function updateQuestionId(number) {
        setQuestionId(number)
        setIsShowAnswer(false)
    }

    function getNextQuestionButton(questionId) {
        return (
            <div className="position-absolute bottom-0 end-0">
                <Link to={`/question?id=${getNextQuestionId()}`} className="btn btn-success me-3 mb-3"
                      onClick={() => updateQuestionId(getNextQuestionId())}>
                    {((questionId + 1) < quiz.questions.length) ? "Next question" : "End quiz"}
                </Link>
            </div>
        )
    }

    function getPreviousQuestionButton(questionId) {
        if ((questionId - 1) >= 0) {
            return (
                <div className="position-absolute bottom-0 start-0">
                    <Link to={`/question?id=${questionId - 1}`} className="btn btn-danger ms-3 mb-3"
                          onClick={() => updateQuestionId(questionId - 1)}>Previous
                        question</Link>
                </div>
            )
        }
    }

    function getStartBody(quiz) {
        return (
            <div className="background">
                <Card className="col-lg-4">
                    <Card.Body>
                        <h1>{quiz.name}</h1>
                        <small className="ms-2">By <i>{quiz.author}</i></small> <br/>
                        <small className="ms-2">Created <i>{quiz.created}</i></small>
                        <h5 className="ms-2 mt-4">{quiz.questions.length} questions</h5>
                        <Link to={`/question?id=${0}`} className="btn btn-dark mb-3 mt-3 mx-auto"
                              onClick={() => updateQuestionId(0)}>First question</Link>
                    </Card.Body>
                </Card>

            </div>
        )
    }

    function handleResult() {
        updateQuestionId(0)
    }

    function getEndOfQuizBody() {
        return (
            <div className="background">
                <Card>
                    <Card.Body>
                        <h1>{quiz.name}</h1>
                        <h2>{`${quiz.questions.length} of ${quiz.questions.length} questions done`}</h2>
                        <div className="text-center mt-4">
                            <Link to={"/question?id=0"} onClick={handleResult} className="btn btn-dark">Check
                                answers</Link>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }

    function getLetter(number) {
        const alphabet = "abcdefghijklmnopqrstuvwxyzæøå"
        if (number < alphabet.length) {
            return alphabet.charAt(number)
        } else {
            number = number % alphabet.length
            return `0${alphabet.charAt(number)}`
        }
    }

    function handleShowAnswer() {
        setIsShowAnswer(!isShowAnswer);
    }

    function handleVideoShowAnswer() {
        setIsShowAnswer(!isShowAnswer)
        if (!isShowAnswer) {
            document.getElementById("answerVideo").className = "text-center";
        } else {
            document.getElementById("answerVideo").className = "text-center visually-hidden";
        }
    }

    function rainbowText(text) {
        let array = []
        const colors = [
            '#F94144',
            '#F3722C',
            '#F8961E',
            '#F9844A',
            '#F9C74F',
            '#90BE6D',
            '#43AA8B',
            '#4D908E',
            '#577590',
            '#277DA1'
        ]
        for (let i = 0; i < text.length; i++) {
            array.push(
                <span style={{color: colors[i % colors.length]}}>{text.charAt(i)}</span>
            )
        }

        return array
    }

    if (questionId === quiz.id) {
        return getStartBody(quiz)
    } else if (questionId === 1337) {
        return getEndOfQuizBody()
    }

    const question = quiz.questions[questionId]
    if (question === null || question === undefined) {
        return <Error httpCode={400} message={"There is no question with id " + questionId}/>
    }

    if (question.question === "10fortunewheel") {
        return (
            <div className="background">
                <h1 className={styles.title}>
                    {rainbowText("Lykkehjulet! :)")}
                </h1>
                <Card className="col-lg-6">
                    <Card.Body>
                        <div>
                            <FortuneWheel/>
                        </div>
                        <div className="mt-5 buttons">
                            {getPreviousQuestionButton(questionId)}
                            {getNextQuestionButton(questionId)}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }

    if (question.question.startsWith("10video")) {
        return (
            <div className="background">
                <h1 className={styles.title}>{question.question.replace("10video", "")}</h1>
                <Card className="col-lg-6">
                    <Card.Body>
                        <div className={styles.video}>
                            <ReactPlayer url={"http://quiz.fritjof.no/video.mp4"}
                                         loop={true} muted={true} playing={true}/>
                        </div>
                        <div id="answerVideo" className="text-center visually-hidden">
                            <Form.Control type="text"
                                          className={`answer1 input-answer answer-true`}
                                          value={question.answers[0].value} disabled={true}/>
                        </div>
                        <div className="mt-5 buttons">
                            {getPreviousQuestionButton(questionId)}
                            {getNextQuestionButton(questionId)}
                        </div>
                    </Card.Body>
                </Card>
                <div>
                    <Button className={"btn btn-primary mt-3"} onClick={handleVideoShowAnswer}
                            title="Show the answer :)">
                        {isShowAnswer ? "Gjem svaret (Husk å gjemme det igjen)" : "Vis svaret"}
                    </Button>
                </div>
            </div>
        )
    }

    if (question.question.startsWith("10song")) {
        return (
            <div className="background">
                <h1 className={styles.title}>{question.question.replace("10song", "")}</h1>
                <Card className="col-lg-6">
                    <Card.Body>
                        <div className="text-center mt-5 mb-4">
                            <Button className="btn btn-dark btn-lg" onClick={()=> {
                                audio.loop = false;
                                audio.play();
                            }}>Play</Button>
                        </div>
                        <div id="answerVideo" className="text-center visually-hidden">
                            <Form.Control type="text"
                                          className={`answer1 input-answer answer-true`}
                                          value={question.answers[0].value} disabled={true}/>
                        </div>
                        <div className="mt-5 buttons">
                            {getPreviousQuestionButton(questionId)}
                            {getNextQuestionButton(questionId)}
                        </div>
                    </Card.Body>
                </Card>
                <div>
                    <Button className={"btn btn-primary mt-3"} onClick={handleVideoShowAnswer}
                            title="Show the answer :)">
                        {isShowAnswer ? "Gjem svaret" : "Vis svaret"}
                    </Button>
                </div>
            </div>
        )
    }

    let answers = []
    for (let i = 0; i < question.answers.length; i++) {
        answers.push(
            <div key={`quiz-${i}`} className={`col-lg-10 mx-auto`}>
                <Form.Label className={styles.label} htmlFor={`name-answer-${i + 1}`}>{getLetter(i)})</Form.Label>
                <Form.Control name={`name-answer-${i + 1}`} type="text"
                              className={`answer${i + 1} input-answer ${isShowAnswer ? `answer-${question.answers[i].isCorrect}` : ``}`}
                              value={question.answers[i].value} disabled={true}/>
            </div>
        )
    }

    return (
        <div className="background">
            <h1 className={styles.title}>{question.question}</h1>
            <Card className="col-lg-6">
                <Card.Body>
                    <div className="row">
                        {answers}
                    </div>
                    <div className="mt-5 buttons">
                        {getPreviousQuestionButton(questionId)}
                        {getNextQuestionButton(questionId)}
                    </div>
                </Card.Body>
            </Card>
            <div>
                <Button className={"btn btn-primary mt-3"} onClick={handleShowAnswer} title="Show the answer :)">
                    {isShowAnswer ? "Gjem svaret" : "Vis svaret"}
                </Button>
            </div>
        </div>
    )
}