import React, {useState} from 'react';
import styles from './Login.module.css';
import {Button, Card, Form} from "react-bootstrap";
import {Link} from "react-router-dom";


function Login() {
    const [quizId, setQuizId] = useState('')

    function handleQuizId() {
        if (quizId === undefined || quizId.length !== 6) {
            console.error("Invalid length")
        } else if (isNaN(quizId)) {
            console.error("Input is not a number")
        } else if (quizId !== "123456") {
            console.error("Invalid quizId") // TODO : Replace this with an actual check
        } else {
            const prettyNumber = quizId[0] + quizId[1] + quizId[2] + " " + quizId[3] + quizId[4] + quizId[5]
            console.info(prettyNumber)
            window.location = "/loading?quizId=" + quizId
        }
    }

    return (
        <div className="background">
            <h1 className={styles.title}>KaHøøt!</h1>
            <Card className="col-lg-2">
                <Card.Body>
                    <Form className="d-grid gap-1">
                        <Form.Group className="mb-3" controlId="pin">
                            <Form.Control type="text" value={quizId} onChange={(e) => setQuizId(e.target.value)}
                                          placeholder="PIN for spillet"/>
                        </Form.Group>
                        <Button type="button" className="btn btn-dark mb-3" onClick={handleQuizId}>
                            Start
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="text-right p-3 position-absolute fixed-bottom">
                <Link to="/create" className={styles.createQuiz}>Lag en quiz</Link>
            </div>
        </div>
    );
}

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
