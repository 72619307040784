import './App.css';
import Login from "./Login/Login";
import LoadingScreen from "./LoadingScreen/LoadingScreen";
import {Route, Routes} from "react-router-dom";
import Create from "./Create/Create";
import QuestionSlide from "./QuestionSlide/QuestionSlide";
import "./fonts/Montserrat.ttf"

function App() {
    return (
        <Routes>
            <Route index element={<Login/>}></Route>
            <Route path="loading" element={<LoadingScreen/>}/>
            <Route path="create" element={<Create/>}/>
            <Route path="question" element={<QuestionSlide/>}/>
        </Routes>
    );
}

export default App;
