import React from 'react';
import {Card} from "react-bootstrap";

export default function Error(props) {
    return (
        <div className="background">
            <Card>
                <Card.Body>
                    <div className="text-center">
                        <h1>Error {props.httpCode}</h1>
                        <h2>{props.message}</h2>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

Error.propTypes = {};

Error.defaultProps = {};

