import React from 'react';
import styles from './LoadingScreen.module.css';
import {Card} from "react-bootstrap";
import Error from "../Error/Error";
import {Link} from "react-router-dom";

const players = []

function getUsers(quizId) {
    if (players.length === 0) {
        return (
            <Card>
                <Card.Body className="m-2">
                    <h4>Venter på spillere...</h4>
                    <div className="text-center mt-5 mb-0">
                        <Link to={"/question?id=" + quizId} className="btn btn-dark">Start quiz</Link>
                    </div>
                </Card.Body>
            </Card>
        )
    } else {
        return (
            <h1>{players.join(", ")}</h1>
        )
    }
}

function randomNumber(quizId) {
    return quizId[0] + quizId[1] + quizId[2] + " " + quizId[3] + quizId[4] + quizId[5]
}

export default function LoadingScreen() {
    const queryParameters = new URLSearchParams(window.location.search)
    const quizId = queryParameters.get("quizId")
    if (quizId.length !== 6 || isNaN(quizId)) {
        return (
            <Error httpCode={400} message="Not a valid quizId"/>
        )
    }

    return (
        <div className="background">
            <Card className="fixed-top col-lg-3 mt-4 mx-auto text-center">
                <Card.Body>
                    <h3>PIN for spillet:</h3>
                    <h1 className={styles.pinNumber}>{randomNumber(quizId)}</h1>
                    <small>Delta på <a href="www.quiz.fritjof.no">www.quiz.fritjof.no</a></small>
                </Card.Body>
            </Card>
            <div>
                {getUsers(quizId)}
            </div>
        </div>
    );
}

LoadingScreen.propTypes = {};

LoadingScreen.defaultProps = {};
